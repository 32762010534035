<template>
  <div>
    <div class="row gutter-b">
      <div class="col-md-12">
        <div class="card card-custom">
          <div class="card-header border-0 px-6">
            <div class="row align-items-center">
              
              </div>
              <div class="card-toolbar">
                <b-button
                  variant="success"
                  v-b-modal.modal-position-edit
                >
                  <span class="svg-icon svg-icon-md svg-icon-white" >
                    <inline-svg src="/media/svg/icons/Navigation/Plus.svg" />
                  </span>
                  {{ $t("positions.button.create") }}
                </b-button>
              </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <!--begin::Card-->
      <PositionItem
        v-for="(role, index) in roles.data"
        :key="index"
        :role="role"
      ></PositionItem>

      
      <b-modal id="modal-position-delete" :okTitle="$t('button.delete')" okVariant="danger" :title="$t('positions.title.confirm_delete_position_title')">
        {{ $t('positions.title.confirm_delete_position_text') }}
          <template #modal-footer="{ cancel }">
              <b-button variant="secondary" @click="cancel()" class="mr-3">
                {{ $t('button.cancel') }}
            </b-button> 
            <b-button variant="danger" @click="deleteRole(selectedRole)">
              {{ $t('positions.button.delete_position') }}
            </b-button>         
          </template>
      </b-modal>

      <b-modal id="modal-position-edit" :okTitle="$t('button.save')" okVariant="success" :title="$t('positions.title.edit_position')">
        <ValidationObserver ref="form">
            <ValidationProvider name="name" immediate rules="required|min:3" v-slot="{ errors }">
            <span class="text-muted mb-2 d-block">{{ $t('positions.title.code_name') }}</span>  
            <input 
                  type="text" 
                  class="form-control form-control-lg form-control-solid"
                  :placeholder="$t('positions.placeholder.edit_name')"
                  v-model="selectedRole.name"
            >                
            <span class="text-danger">{{ errors[0] }}</span>
            
            <span class="text-muted mt-6 mb-2 d-block">{{ $t('positions.title.translates') }}</span>
            <div class="row d-flex ">
              <div class="col-1 px-0 align-self-center text-right">
                <span class="svg-icon svg-icon-lg ">
                  <inline-svg src="/media/svg/flags/cs.svg" />
                </span>
              </div>
              <div class="col-11">
                <input 
                      type="text" 
                      class="form-control form-control-lg form-control-solid" 
                      :placeholder="$t('positions.placeholder.fill_translate', {'lang': 'cs'})"
                  />
              </div>
            </div>
            <div class="row d-flex mt-3">
              <div class="col-1 px-0 align-self-center text-right">
                <span class="svg-icon svg-icon-lg ">
                  <inline-svg src="/media/svg/flags/en.svg" />
                </span>
              </div>
              <div class="col-11">
                <input 
                      type="text" 
                      class="form-control form-control-lg form-control-solid" 
                      :placeholder="$t('positions.placeholder.fill_translate', {'lang': 'en'})"
                  />
              </div>
            </div>
            </ValidationProvider>
        </ValidationObserver>

          <template #modal-footer="{ cancel }">
              <b-button variant="secondary" @click="cancel()" class="mr-3">
                {{ $t('button.cancel') }}
            </b-button> 
            <b-button variant="success" @click="updateRole(selectedRole)">
              {{ $t('positions.button.edit_position') }}
            </b-button>         
          </template>
      </b-modal>

    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import PositionItem from "@/view/pages/core/positions/PositionItem";
import ApiService from "@/core/services/api.service";
import { checkErrors } from "@/core/utils/api-errors";

export default {
  name: "positions_list",
  components: {
    PositionItem
  },
  data() {
    return {
      roles: null,
      loading: false,
      selectedRole: null,
      rulesType: null,
      rulesCategories: null,
      haveRules: []
    };
  },
  beforeMount() {
    this.getRoles();
  },
   metaInfo () { 
      return { title: this.$t("positions.meta.subtitle.list")} 
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("positions.meta.title"), subtitle: this.$t("positions.meta.subtitle.list") }]);
  },
  watch: {
    rulesType: function () {
      this.getRuleCategories();
    },
    selectedRole: function () {
      this.getRoleRules();
    }
  },
  methods: {
    getRoles() {
      this.loading = true;
      ApiService.setHeader();
      ApiService.apiGet(
        "/roles")
        .then((response) => {
          this.roles = response.data;
          this.loading = false;
        });
    },
  	setRole(role){
    	this.selectedRole = role;
    },
  	setRulesType(type){
    	this.rulesType = type;
    },
    deleteRole(role){
      this.loading = true;
      ApiService.setHeader();      
      ApiService.apiPost("roles/" + role.role_id + "/delete")
        .then(() => {
            this.loading = false;
            this.$bvModal.hide('modal-role-delete');
            this.getRoles();
        }).catch(error => {
            checkErrors(error);
      });
    },
    updateRole(role){
      this.loading = true;
      this.$refs.form.validate().then(success => {
        if (!success) {
          this.loading = false;
          this.$toasted.error("Zkontrolujte zadané údaje.");
          return;
        } 
        ApiService.setHeader();      
        ApiService.apiPost("roles/" + role.role_id + "/update",{
          'name': role.name,
        })
          .then(() => {
              this.loading = false;
              this.$bvModal.hide('modal-role-edit');
              this.getRoles();
          }).catch(error => {
              checkErrors(error);
        });
      });
    },
    getRuleCategories() {
      this.loading = true;
      ApiService.setHeader();
      ApiService.apiGet(
        "/rules/" + this.rulesType)
        .then((response) => {
          this.rulesCategories = response.data.data;
          this.loading = false;
        });
    },
    getRoleRules() {
      this.loading = true;
      ApiService.setHeader();
      ApiService.apiGet(
        "/roles/" + this.selectedRole.role_id + "/rules")
        .then((response) => {
          this.haveRules = response.data.data;
          this.loading = false;
        });
    },
    updateHaveRulesList(value){
      this.haveRules = value;
    },
    editHaveRules(){      
      this.loading = true;
      ApiService.setHeader();      
      ApiService.apiPost("roles/" + this.selectedRole.role_id + "/rules",{
        'rules':this.haveRules
      })
        .then(() => {
            this.loading = false;
            this.$bvModal.hide('modal-global-rules');
            this.getRoles();
        }).catch(error => {
            checkErrors(error);
      });
    }
  }
};
</script>
