<template>
    <div class="col-md-4">
        <div class="card card-custom" >
          <div class="card-header">
            <div class="card-title">
                <h5 class="card-label">
                  {{ role.name }}
                </h5>
            </div>
            <div class="card-toolbar"> 
                <a class="btn btn-link btn-sm btn btn-clean btn-hover-light-danger btn-sm btn-icon" v-b-tooltip.top="$t('positions.title.delete_position')" v-b-modal.modal-position-delete @click="setRole(role)"><i class="ki ki-bold-close icon-sm text-danger"></i></a>
                <a class="btn btn-link btn-sm btn btn-clean btn-hover-light-primary btn-sm btn-icon" v-b-tooltip.top="$t('button.settings')" v-b-modal.modal-position-edit @click="setRole(role)"><i class="ki ki-gear icon-md"></i></a>
                <a class="btn btn-link btn-sm btn btn-clean btn-hover-light-primary btn-sm btn-icon font-weight-bold" v-b-tooltip.top="$t('roles.title.num_of_users')">54</a>
            </div>
          </div>
        </div>
    </div>
</template>
<script>
export default {
  props: {
    role: Object
  },
  methods: {
  	setRole(role){
    	this.$parent.setRole(role);
    },
    setRulesType(type){
    	this.$parent.setRulesType(type);
    }  
  }
};
</script>